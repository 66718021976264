<template>
  <div class="flex flex-col gap-6">
    <div>
      <vs-button v-on:click="create()" icon="done" color="primary">
        Create Promo Type
      </vs-button>
    </div>

    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead"> </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.creditNotePromoType"
            >
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleEdit(tr)"
                />
                <feather-icon
                  title="Delete"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click.stop="handleDelete(tr.ID)"
                />
              </vs-td>
              <vs-td> {{ tr.Name }} </vs-td>
              <vs-td>
                Promotion Fee Coa : {{ tr.MappingGl[0].PromotionFeeCoa }} <br />
                Withholding Tax Coa : {{ tr.MappingGl[0].WithholdingTaxCoa }}
                <br />
                Item Tax Code : {{ tr.MappingGl[0].ItemTaxCode }} <br />
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <create
              @closeDetail="closeDetail"
              :id="id"
              :data="selected"
              :url="baseUrl"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import create from "./create.vue";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  components: {
    create,
  },
  data() {
    return {
      id: null,
      typePayment: ["", "Real Account", "VA", "Wallet"],
      baseUrl: "/api/v1/credit-note-promotion-type",
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "created_at",
        sort: "desc",
      },
      type: 2,
      header: [
        {
          text: "",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Detail",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.reloadData(this.params);
  },
  methods: {
    create() {
      this.id = 0;
      this.selected = {
        ID: 0,
      };
      this.detail = true;
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    reloadData(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            type: params.type ? params.type : 0,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data = resp.data.creditNotePromoType;
            this.responseData = resp.data;
            this.responseData.length = resp.data.creditNotePromoType.length;
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.reloadData(this.params);
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(selected) {
      this.selected = selected;
      console.log(this.selected);
      this.id = selected.ID;
      this.detail = true;
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
